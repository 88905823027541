// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.MainButton_mainButton__aLF-o{
    background-color: rgb(17, 187, 209);
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 700;
}
.MainButton_resetButton__hrBwO{
    background-color: #fff;
    padding: 24px 24px;
    color: rgb(17, 187, 209);
    font-size: 15px;
    font-weight: 700;
    outline: none;
    border: none;
}
.MainButton_paymentButton__8Xz5W{
    background-color: rgb(17, 187, 209);
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 700;
    width: 200px;
   
}
.MainButton_inputField__8eE1s{
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid rgb(229, 229, 229);
    height: 40px;
    outline: none;
    /* width: 100%; */
    transition: .3s;
}

.MainButton_inputField__8eE1s:required:invalid {
    border: 1px solid rgb(240, 149, 149);
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/MainButton.module.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;IACI,mCAAmC;IACnC,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,YAAY;AAChB;AACA;IACI,mCAAmC;IACnC,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;;AAEhB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,oCAAoC;IACpC,YAAY;IACZ,aAAa;IACb,iBAAiB;IAGjB,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":["/*\n* Prefixed by https://autoprefixer.github.io\n* PostCSS: v8.4.14,\n* Autoprefixer: v10.4.7\n* Browsers: last 4 version\n*/\n\n.mainButton{\n    background-color: rgb(17, 187, 209);\n    padding: 12px 24px;\n    border-radius: 8px;\n    font-size: 15px;\n    font-weight: 700;\n}\n.resetButton{\n    background-color: #fff;\n    padding: 24px 24px;\n    color: rgb(17, 187, 209);\n    font-size: 15px;\n    font-weight: 700;\n    outline: none;\n    border: none;\n}\n.paymentButton{\n    background-color: rgb(17, 187, 209);\n    padding: 12px 24px;\n    border-radius: 8px;\n    font-size: 15px;\n    font-weight: 700;\n    width: 200px;\n   \n}\n.inputField{\n    border-radius: 8px;\n    padding: 8px 16px;\n    border: 1px solid rgb(229, 229, 229);\n    height: 40px;\n    outline: none;\n    /* width: 100%; */\n    -webkit-transition: .3s;\n    -o-transition: .3s;\n    transition: .3s;\n}\n\n.inputField:required:invalid {\n    border: 1px solid rgb(240, 149, 149);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainButton": `MainButton_mainButton__aLF-o`,
	"resetButton": `MainButton_resetButton__hrBwO`,
	"paymentButton": `MainButton_paymentButton__8Xz5W`,
	"inputField": `MainButton_inputField__8eE1s`
};
export default ___CSS_LOADER_EXPORT___;
