// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../styles/variables.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
}

.create-btn {
  background: #6366f1;
  padding: 10px 16px;
  border: none;
}

.create-btn:hover {
  background-color: #818cf8;
  border: none;
}

.page-link {
  color: #6c757d;
}

.active > .page-link {
  background-color: #6366f1;
  border: none;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages-crm/Kindergartens/styles/Kindergartens.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,oBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,kBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,cAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,YAAA;EACA,kBAAA;AAAJ","sourcesContent":["@import url('../../../styles/variables.scss');\r\n\r\n.header-title{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding-bottom: 32px;\r\n}\r\n\r\n.create-btn{\r\n    background: #6366f1;\r\n    padding: 10px 16px;\r\n    border: none;\r\n}\r\n\r\n.create-btn:hover{\r\n    background-color: #818cf8;\r\n    border: none;\r\n}\r\n\r\n.page-link{\r\n    color: #6c757d;\r\n}\r\n\r\n.active>.page-link{\r\n    background-color: #6366f1;\r\n    border: none;\r\n    border-radius: 4px\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
