// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.ButtonBurger_burger__NUGmG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  align-items: flex-end;
  gap: 4px;
}

.ButtonBurger_line__8oZjN {
  width: 16px;
  height: 0px;
  border: 1.5px solid #6e6e6e;
  border-radius: 2px;
}

.ButtonBurger_line__8oZjN:nth-of-type(1) {
  width: 8px;
}

.ButtonBurger_line__8oZjN:nth-of-type(3) {
  width: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/ButtonBurger/ButtonBurger.module.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EAGE,aAAa;EAIL,sBAAsB;EAGtB,uBAAuB;EAC/B,6BAA6B;EAGrB,qBAAqB;EAC7B,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,WAAW;EACX,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":["/*\n* Prefixed by https://autoprefixer.github.io\n* PostCSS: v8.4.14,\n* Autoprefixer: v10.4.7\n* Browsers: last 4 version\n*/\n\n.burger {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  background-color: transparent;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n  gap: 4px;\n}\n\n.line {\n  width: 16px;\n  height: 0px;\n  border: 1.5px solid #6e6e6e;\n  border-radius: 2px;\n}\n\n.line:nth-of-type(1) {\n  width: 8px;\n}\n\n.line:nth-of-type(3) {\n  width: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"burger": `ButtonBurger_burger__NUGmG`,
	"line": `ButtonBurger_line__8oZjN`
};
export default ___CSS_LOADER_EXPORT___;
