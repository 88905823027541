// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.NavBar_navBar__P88J\\+ {
  width: 100%;
  background-color: #fff;
}

.NavBar_container__YuZWp {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.NavBar_userInfoList__T-zKo {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
  padding: 0 16px;
}

.NavBar_navList__9xlrX {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px 0;
  padding: 0 16px;
}

.NavBar_socialList__nfvdR {
  display: flex;
  gap: 40px;
  border-top: 1px solid #e5e5e5;
  padding: 40px 16px;
}
.NavBar_linkToAuth__q1s0o>a{
  color: #11bbd1;
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.module.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EACE,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,YAAY;EAGZ,aAAa;EAIL,sBAAsB;AAChC;;AAEA;EAGE,aAAa;EAIL,sBAAsB;EAC9B,SAAS;EACT,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EAGE,aAAa;EAIL,sBAAsB;EAC9B,SAAS;EACT,cAAc;EACd,eAAe;AACjB;;AAEA;EAGE,aAAa;EACb,SAAS;EACT,6BAA6B;EAC7B,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":["/*\n* Prefixed by https://autoprefixer.github.io\n* PostCSS: v8.4.14,\n* Autoprefixer: v10.4.7\n* Browsers: last 4 version\n*/\n\n.navBar {\n  width: 100%;\n  background-color: #fff;\n}\n\n.container {\n  height: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n\n.userInfoList {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 24px;\n  margin-top: 40px;\n  padding: 0 16px;\n}\n\n.navList {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 40px;\n  margin: 40px 0;\n  padding: 0 16px;\n}\n\n.socialList {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 40px;\n  border-top: 1px solid #e5e5e5;\n  padding: 40px 16px;\n}\n.linkToAuth>a{\n  color: #11bbd1;\n  font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBar": `NavBar_navBar__P88J+`,
	"container": `NavBar_container__YuZWp`,
	"userInfoList": `NavBar_userInfoList__T-zKo`,
	"navList": `NavBar_navList__9xlrX`,
	"socialList": `NavBar_socialList__nfvdR`,
	"linkToAuth": `NavBar_linkToAuth__q1s0o`
};
export default ___CSS_LOADER_EXPORT___;
