// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.Rules_list__is93X {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 100px;
  padding: 16px 40px 40px;
}

@media screen and (max-width: 768px) {
  .Rules_list__is93X {
    margin-left: 0;
    padding: 0 16px 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Rules/Rules.module.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EAGE,aAAa;EAIL,sBAAsB;EAC9B,SAAS;EACT,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE;IACE,cAAc;IACd,oBAAoB;EACtB;AACF","sourcesContent":["/*\n* Prefixed by https://autoprefixer.github.io\n* PostCSS: v8.4.14,\n* Autoprefixer: v10.4.7\n* Browsers: last 4 version\n*/\n\n.list {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 24px;\n  margin-left: 100px;\n  padding: 16px 40px 40px;\n}\n\n@media screen and (max-width: 768px) {\n  .list {\n    margin-left: 0;\n    padding: 0 16px 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `Rules_list__is93X`
};
export default ___CSS_LOADER_EXPORT___;
