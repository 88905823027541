// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-shadow {
  box-shadow: 0 10px 13px 0 rgba(17, 38, 146, 0.05);
  height: fit-content;
}

.card-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}

.card-header-title {
  font-weight: 600;
  font-size: 20px;
}

.card-footer {
  background-color: #fff;
}

.card-burger {
  cursor: pointer;
}

.card-popup-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 0 8px 0;
}

.card-icon {
  cursor: pointer;
}

.popover-arrow::after {
  display: none !important;
}

.popover-arrow::before {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components-crm/ClientCard/style/ClientCard.scss"],"names":[],"mappings":"AAAA;EACI,iDAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,qBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,sBAAA;AACJ;;AACA;EACI,gBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,sBAAA;AAGJ;;AADA;EACI,eAAA;AAIJ;;AAFA;EACI,aAAA;EACD,mBAAA;EACA,8BAAA;EACA,QAAA;EACA,oBAAA;AAKH;;AAHA;EACI,eAAA;AAMJ;;AAJA;EACI,wBAAA;AAOJ;;AALA;EACI,wBAAA;AAQJ","sourcesContent":[".card-shadow {\r\n    box-shadow: 0 10px 13px 0 rgba(17, 38, 146, 0.05);\r\n    height: fit-content;\r\n}\r\n\r\n.card-wrap {\r\n    display: grid;\r\n    grid-template-columns: repeat(4, 1fr);\r\n    grid-template-rows: repeat(3, 1fr);\r\n    grid-column-gap: 24px;\r\n    grid-row-gap: 24px;\r\n}\r\n\r\n.card-header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    background-color: #fff;\r\n}\r\n.card-header-title{\r\n    font-weight: 600;\r\n    font-size: 20px;\r\n}\r\n.card-footer{\r\n    background-color: #fff;\r\n}\r\n.card-burger{\r\n    cursor: pointer;\r\n}\r\n.card-popup-btn{\r\n    display: flex;\r\n   align-items: center;\r\n   justify-content: space-between;\r\n   gap: 8px;\r\n   padding: 8px 0 8px 0\r\n}\r\n.card-icon{\r\n    cursor: pointer;\r\n}\r\n.popover-arrow::after{\r\n    display: none !important;\r\n}\r\n.popover-arrow::before{\r\n    display: none !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
