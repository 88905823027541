// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vendor/normalize.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vendor/fonts/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "NunitoSans", Arial, sans-serif;
  color: #090909;
  background-color: #fff;
}

.page {
  margin: 0;
  min-width: 320px;
  box-sizing: border-box;
  font-family: "NunitoSans", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  /* border-bottom: 1px solid rgb(229, 229, 229); */
}

#qrCodeContainer {
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
}
video{
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto !important;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  /* z-index: 12; */
}
#qr-shaded-region{
  border-width: 50px !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,4CAA4C;EAC5C,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,sBAAsB;EACtB,4CAA4C;EAC5C,kCAAkC;EAClC,mCAAmC;EACnC,8BAA8B;EAC9B,0BAA0B;EAC1B,2BAA2B;EAC3B,iDAAiD;AACnD;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,gCAAgC;EAChC,iBAAiB;AACnB;AACA;EACE,6BAA6B;AAC/B","sourcesContent":["@import url(./vendor/normalize.css);\n@import url(./vendor/fonts/fonts.css);\n\nbody {\n  font-family: \"NunitoSans\", Arial, sans-serif;\n  color: #090909;\n  background-color: #fff;\n}\n\n.page {\n  margin: 0;\n  min-width: 320px;\n  box-sizing: border-box;\n  font-family: \"NunitoSans\", Arial, sans-serif;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-font-smoothing: antialiased;\n  -webkit-text-size-adjust: 100%;\n  -ms-text-size-adjust: 100%;\n  -moz-text-size-adjust: 100%;\n  /* border-bottom: 1px solid rgb(229, 229, 229); */\n}\n\n#qrCodeContainer {\n  position: relative;\n  width: 500px;\n  height: 500px;\n  border-radius: 8px;\n  overflow: hidden;\n}\nvideo{\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: auto !important;\n  min-width: 100%;\n  min-height: 100%;\n  transform: translate(-50%, -50%);\n  /* z-index: 12; */\n}\n#qr-shaded-region{\n  border-width: 50px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
