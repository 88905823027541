// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_dropdown__Inf8y{
    width: 436px;
    position: relative;
    margin-bottom: 100px;
}
/* .dropdown .dropdownBtn{
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid rgb(229, 229, 229);
    height: 40px;
} */
.Dropdown_inputField__QzV55{
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid rgb(229, 229, 229);
    height: 40px;
    outline: none;
    width: 436px;
    transition: .3s;
}
.Dropdown_dropdownContent__012Fr{
    position: absolute;
    top: 100%;
    /* padding: 5px; */
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid rgb(229, 229, 229);
    height: 40px;
    width: 100%;
    color: rgb(110, 110, 110);
    transition: .3s;
}

.Dropdown_dropdownItem__VThBM:hover{
    background: rgb(248, 249, 251);
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/ManagerProfile/Dropdown/Dropdown.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;AACxB;AACA;;;;;GAKG;AACH;IACI,kBAAkB;IAClB,iBAAiB;IACjB,oCAAoC;IACpC,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,oCAAoC;IACpC,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".dropdown{\r\n    width: 436px;\r\n    position: relative;\r\n    margin-bottom: 100px;\r\n}\r\n/* .dropdown .dropdownBtn{\r\n    border-radius: 8px;\r\n    padding: 8px 16px;\r\n    border: 1px solid rgb(229, 229, 229);\r\n    height: 40px;\r\n} */\r\n.inputField{\r\n    border-radius: 8px;\r\n    padding: 8px 16px;\r\n    border: 1px solid rgb(229, 229, 229);\r\n    height: 40px;\r\n    outline: none;\r\n    width: 436px;\r\n    transition: .3s;\r\n}\r\n.dropdownContent{\r\n    position: absolute;\r\n    top: 100%;\r\n    /* padding: 5px; */\r\n    border-radius: 8px;\r\n    padding: 8px 16px;\r\n    border: 1px solid rgb(229, 229, 229);\r\n    height: 40px;\r\n    width: 100%;\r\n    color: rgb(110, 110, 110);\r\n    transition: .3s;\r\n}\r\n\r\n.dropdownItem:hover{\r\n    background: rgb(248, 249, 251);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `Dropdown_dropdown__Inf8y`,
	"inputField": `Dropdown_inputField__QzV55`,
	"dropdownContent": `Dropdown_dropdownContent__012Fr`,
	"dropdownItem": `Dropdown_dropdownItem__VThBM`
};
export default ___CSS_LOADER_EXPORT___;
