// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUsItem_aboutUsItem__9BoNp {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AboutUsItem_img__kxjgh {
  border-radius: 20px;
}

.AboutUsItem_textBlock__RJwm1 {
  position: absolute;
  right: 64px;
  max-width: 428px;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 80px 40px 48px 48px;
}

.AboutUsItem_line__GqKCv {
  display: block;
  width: 80px;
  height: 3px;
  background-color: #11bbd1;
  border-radius: 2px;
}

.AboutUsItem_text__gZZBa {
  color: #090909;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  margin: 24px 0;
}

@media screen and (max-width: 1400px) {
  .AboutUsItem_textBlock__RJwm1 {
    position: static;
    max-width: 100%;
    padding: 48px;
  }
}

@media screen and (max-width: 768px) {
  .AboutUsItem_textBlock__RJwm1 {
    padding: 40px 32px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AboutUs/AboutUsItem/AboutUsItem.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,WAAW;EACX,sBAAsB;EACtB,sBAAsB;EACtB,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;IACf,aAAa;EACf;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".aboutUsItem {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.img {\n  border-radius: 20px;\n}\n\n.textBlock {\n  position: absolute;\n  right: 64px;\n  max-width: 428px;\n  width: 100%;\n  background-color: #fff;\n  box-sizing: border-box;\n  border-radius: 20px;\n  padding: 80px 40px 48px 48px;\n}\n\n.line {\n  display: block;\n  width: 80px;\n  height: 3px;\n  background-color: #11bbd1;\n  border-radius: 2px;\n}\n\n.text {\n  color: #090909;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 160%;\n  margin: 24px 0;\n}\n\n@media screen and (max-width: 1400px) {\n  .textBlock {\n    position: static;\n    max-width: 100%;\n    padding: 48px;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .textBlock {\n    padding: 40px 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutUsItem": `AboutUsItem_aboutUsItem__9BoNp`,
	"img": `AboutUsItem_img__kxjgh`,
	"textBlock": `AboutUsItem_textBlock__RJwm1`,
	"line": `AboutUsItem_line__GqKCv`,
	"text": `AboutUsItem_text__gZZBa`
};
export default ___CSS_LOADER_EXPORT___;
