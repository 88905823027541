// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* normalize.css */

/* Document
   ========================================================================== */
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
     ========================================================================== */
body {
  margin: 0;
}

/* Grouping content
     ========================================================================== */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
code,
kbd,
samp,
ul,
ol,
li,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
  padding: 0;
}

button {
  font-family: "NunitoSans", sans-serif;
  margin: 0;
  padding: 0;
  border: none;
  background-color: inherit;
  color: #fff;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:disabled {
  cursor: auto;
}

section {
  margin: 0;
  padding: 0;
}

img {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/vendor/normalize.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;+EAC+E;AAC/E;EACE,iBAAiB,EAAE,MAAM;EACzB,8BAA8B,EAAE,MAAM;AACxC;;AAEA;iFACiF;AACjF;EACE,SAAS;AACX;;AAEA;iFACiF;AACjF;;;;;;;;;;;;;;;;;;;EAmBE,SAAS;EACT,UAAU;AACZ;;AAEA;;EAEE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,qCAAqC;EACrC,SAAS;EACT,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb","sourcesContent":["/* normalize.css */\n\n/* Document\n   ========================================================================== */\nhtml {\n  line-height: 1.15; /* 1 */\n  -webkit-text-size-adjust: 100%; /* 2 */\n}\n\n/* Sections\n     ========================================================================== */\nbody {\n  margin: 0;\n}\n\n/* Grouping content\n     ========================================================================== */\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nblockquote,\npre,\nabbr,\ncode,\nkbd,\nsamp,\nul,\nol,\nli,\ndl,\ndd,\ndt {\n  margin: 0;\n  padding: 0;\n}\n\nul,\nli {\n  list-style: none;\n  padding: 0;\n}\n\nbutton {\n  font-family: \"NunitoSans\", sans-serif;\n  margin: 0;\n  padding: 0;\n  border: none;\n  background-color: inherit;\n  color: #fff;\n  cursor: pointer;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n\nbutton:disabled {\n  cursor: auto;\n}\n\nsection {\n  margin: 0;\n  padding: 0;\n}\n\nimg {\n  box-sizing: border-box;\n}\n\na {\n  text-decoration: none;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
