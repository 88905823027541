// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendarpicker-wrap > .air-datepicker {
  width: 772px;
  height: fit-content;
}
.calendarpicker-wrap .air-datepicker-body--cells.-days- {
  grid-auto-rows: 100px;
}
.calendarpicker-wrap .air-datepicker-cell {
  font-size: 15px;
  font-weight: 500;
  width: 110px;
  height: 100px;
}
.calendarpicker-wrap .air-datepicker-nav--action[data-action=prev] {
  right: 120px;
}
.calendarpicker-wrap .air-datepicker-nav--action[data-action=next] {
  right: 35px;
}
.calendarpicker-wrap .air-datepicker-nav {
  position: relative !important;
  border: none;
  padding: 35px;
}`, "",{"version":3,"sources":["webpack://./src/components-crm/CalendarPicker/style/CalendarPicker.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,mBAAA;AAAR;AAEI;EACI,qBAAA;AAAR;AAEI;EACI,eAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAEI;EACI,WAAA;AAAR;AAEI;EACI,6BAAA;EACA,YAAA;EACA,aAAA;AAAR","sourcesContent":[".calendarpicker-wrap{\r\n    &>.air-datepicker{\r\n        width: 772px;\r\n        height: fit-content\r\n    }\r\n    .air-datepicker-body--cells.-days-{\r\n        grid-auto-rows: 100px;\r\n    }\r\n    .air-datepicker-cell {\r\n        font-size: 15px;\r\n        font-weight: 500;\r\n        width: 110px;\r\n        height: 100px;\r\n    }\r\n    .air-datepicker-nav--action[data-action='prev']{\r\n        right: 120px;\r\n    }\r\n    .air-datepicker-nav--action[data-action='next']{\r\n        right: 35px;\r\n    }\r\n    .air-datepicker-nav{\r\n        position: relative !important;\r\n        border: none;\r\n        padding: 35px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
