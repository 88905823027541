// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title_title__mrNq5 {
  color: #090909;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  margin-left: 100px;
  padding: 60px 40px 40px;
}

@media screen and (max-width: 768px) {
  .Title_title__mrNq5 {
    margin-left: 0;
    padding: 40px 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE;IACE,cAAc;IACd,kBAAkB;EACpB;AACF","sourcesContent":[".title {\n  color: #090909;\n  font-family: \"Nunito\";\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 20px;\n  margin-left: 100px;\n  padding: 60px 40px 40px;\n}\n\n@media screen and (max-width: 768px) {\n  .title {\n    margin-left: 0;\n    padding: 40px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Title_title__mrNq5`
};
export default ___CSS_LOADER_EXPORT___;
