// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/Profile/copyBtn.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatisticItem_itemWrap__Qwl68{
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    width: 31%;
    position: relative;
}
@media (min-width: 1080px) and (max-width: 1440px) {
    .StatisticItem_itemWrap__Qwl68{
        width: 46%;
    }
 }
 @media (min-width: 640px) and (max-width: 1079px) {
    .StatisticItem_itemWrap__Qwl68{
        width: 95%;
    }
 }
 @media (min-width: 360px) and (max-width: 639px) {
    .StatisticItem_itemWrap__Qwl68{
        width: 95%;
        padding: 0 15px;
    }
 }
.StatisticItem_itemWrapTimer__Qican{
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    width: 85%;
    position: relative;
}
.StatisticItem_itemLabel__G-ToG{
    font-weight: 400;
    font-size: 18px;
}
.StatisticItem_itemData__n-sV7{
    padding: 16px 0;
    font-weight: 600;
    font-size: 18px;
    background-color: rgb(248, 249, 251);
    text-align: center;
}
.StatisticItem_itemCopy__dHvug{
    position: absolute;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 20px;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    right: 32px;
    top: 63%;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/ManagerProfile/StatisticItem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,kBAAkB;AACtB;AACA;IACI;QACI,UAAU;IACd;CACH;CACA;IACG;QACI,UAAU;IACd;CACH;CACA;IACG;QACI,UAAU;QACV,eAAe;IACnB;CACH;AACD;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,oCAAoC;IACpC,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,yDAAiE;IACjE,qBAAqB;IACrB,4BAA4B;IAC5B,WAAW;IACX,YAAY;IACZ,WAAW;IACX,QAAQ;IACR,eAAe;AACnB","sourcesContent":[".itemWrap{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 24px;\r\n    text-align: center;\r\n    width: 31%;\r\n    position: relative;\r\n}\r\n@media (min-width: 1080px) and (max-width: 1440px) {\r\n    .itemWrap{\r\n        width: 46%;\r\n    }\r\n }\r\n @media (min-width: 640px) and (max-width: 1079px) {\r\n    .itemWrap{\r\n        width: 95%;\r\n    }\r\n }\r\n @media (min-width: 360px) and (max-width: 639px) {\r\n    .itemWrap{\r\n        width: 95%;\r\n        padding: 0 15px;\r\n    }\r\n }\r\n.itemWrapTimer{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 24px;\r\n    text-align: center;\r\n    width: 85%;\r\n    position: relative;\r\n}\r\n.itemLabel{\r\n    font-weight: 400;\r\n    font-size: 18px;\r\n}\r\n.itemData{\r\n    padding: 16px 0;\r\n    font-weight: 600;\r\n    font-size: 18px;\r\n    background-color: rgb(248, 249, 251);\r\n    text-align: center;\r\n}\r\n.itemCopy{\r\n    position: absolute;\r\n    background-image: url(../../../assets/images/Profile/copyBtn.svg);\r\n    background-size: 20px;\r\n    background-repeat: no-repeat;\r\n    width: 22px;\r\n    height: 22px;\r\n    right: 32px;\r\n    top: 63%;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemWrap": `StatisticItem_itemWrap__Qwl68`,
	"itemWrapTimer": `StatisticItem_itemWrapTimer__Qican`,
	"itemLabel": `StatisticItem_itemLabel__G-ToG`,
	"itemData": `StatisticItem_itemData__n-sV7`,
	"itemCopy": `StatisticItem_itemCopy__dHvug`
};
export default ___CSS_LOADER_EXPORT___;
