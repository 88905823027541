// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.SideBarItem_navItem__e3hia {
  width: 100px;
  height: 64px;
}

.SideBarItem_navLink__4u2FU {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.SideBarItem_navItem__e3hia.SideBarItem_active__x-eGn {
  border-right: 1px solid #11bbd1;
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/SideBarItem/SideBarItem.module.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EAGE,aAAa;EAGL,mBAAmB;EAGnB,uBAAuB;EAC/B,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["/*\n* Prefixed by https://autoprefixer.github.io\n* PostCSS: v8.4.14,\n* Autoprefixer: v10.4.7\n* Browsers: last 4 version\n*/\n\n.navItem {\n  width: 100px;\n  height: 64px;\n}\n\n.navLink {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  height: 100%;\n}\n\n.navItem.active {\n  border-right: 1px solid #11bbd1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navItem": `SideBarItem_navItem__e3hia`,
	"navLink": `SideBarItem_navLink__4u2FU`,
	"active": `SideBarItem_active__x-eGn`
};
export default ___CSS_LOADER_EXPORT___;
