// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-img {
  position: absolute;
  top: 25%;
  left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components-crm/Search/styles/Search.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;AACJ","sourcesContent":[".search-img {\r\n    position: absolute;\r\n    top: 25%;\r\n    left: 16px;\r\n}\r\n\r\n.input-search::placeholder {\r\n    // padding-left: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
