// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUs_list__pmCge {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: 100px;
  padding: 0 40px 80px 40px;
}

@media screen and (max-width: 768px) {
  .AboutUs_list__pmCge {
    margin-left: 0;
    padding: 0 16px 40px 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AboutUs/AboutUs.module.css"],"names":[],"mappings":"AAAA;EAGE,aAAa;EAIL,sBAAsB;EAC9B,SAAS;EACT,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE;IACE,cAAc;IACd,yBAAyB;EAC3B;AACF","sourcesContent":[".list {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 40px;\n  margin-left: 100px;\n  padding: 0 40px 80px 40px;\n}\n\n@media screen and (max-width: 768px) {\n  .list {\n    margin-left: 0;\n    padding: 0 16px 40px 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `AboutUs_list__pmCge`
};
export default ___CSS_LOADER_EXPORT___;
