// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.RulesItem_item__53hgZ {
  display: flex;
  align-items: center;
  gap: 16px;
}

.RulesItem_numBlock__yrs5\\+ {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 56px;
  width: 100%;
  height: 56px;
  border: 1px solid #11bbd1;
  border-radius: 4px;
  flex-shrink: 0;
}

.RulesItem_num__F-jpS {
  color: #090909;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RulesItem_text__lKxlE {
  width: 100%;
  color: #090909;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  .RulesItem_item__53hgZ {
    align-items: flex-start;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Rules/RulesItem/RulesItem.module.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EAGE,aAAa;EAGL,mBAAmB;EAC3B,SAAS;AACX;;AAEA;EAGE,aAAa;EAGL,mBAAmB;EAGnB,uBAAuB;EAC/B,eAAe;EACf,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAEb,uBAAuB;AAC5B;;AAEA;EACE,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE;IAGU,uBAAuB;EACjC;AACF","sourcesContent":["/*\n* Prefixed by https://autoprefixer.github.io\n* PostCSS: v8.4.14,\n* Autoprefixer: v10.4.7\n* Browsers: last 4 version\n*/\n\n.item {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 16px;\n}\n\n.numBlock {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  max-width: 56px;\n  width: 100%;\n  height: 56px;\n  border: 1px solid #11bbd1;\n  border-radius: 4px;\n  flex-shrink: 0;\n}\n\n.num {\n  color: #090909;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 24px;\n  white-space: nowrap;\n  overflow: hidden;\n  -o-text-overflow: ellipsis;\n     text-overflow: ellipsis;\n}\n\n.text {\n  width: 100%;\n  color: #090909;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 24px;\n}\n\n@media screen and (max-width: 768px) {\n  .item {\n    -webkit-box-align: start;\n        -ms-flex-align: start;\n            align-items: flex-start;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `RulesItem_item__53hgZ`,
	"numBlock": `RulesItem_numBlock__yrs5+`,
	"num": `RulesItem_num__F-jpS`,
	"text": `RulesItem_text__lKxlE`
};
export default ___CSS_LOADER_EXPORT___;
