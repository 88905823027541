// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderUserInfoItem_text__30RBO {
  color: #6e6e6e;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderUserInfoItem/HeaderUserInfoItem.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".text {\n  color: #6e6e6e;\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `HeaderUserInfoItem_text__30RBO`
};
export default ___CSS_LOADER_EXPORT___;
