// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../styles/variables.scss";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%234f46e5%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-select:focus {
  border: 1px solid #4f46e5;
}

.form-select {
  border-radius: 6px;
  padding: 12px 16px;
  width: 250px;
  height: 48px;
}

.form-control {
  border-radius: 6px;
  padding: 12px 16px 12px 40px;
  height: 48px;
}

.form-control:focus {
  border: 1px solid #4f46e5;
}

.form-control::placeholder {
  font-size: 16px;
}

.form-control-wrap {
  position: relative;
}

.control-img {
  position: absolute;
  top: 55%;
  left: 15px;
}

.form-select {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 16px 16px;
  color: #adb5bd;
}

.form-select .select-option {
  font-weight: 600;
  color: #000;
}

.select-option:hover {
  background-color: #4f46e5 !important;
}

.filter-btn {
  cursor: pointer;
  padding-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components-crm/ClientFilter/styles/ClientFilter.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,4BAAA;EACA,YAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;AAAJ;;AAGA;EACI,yDAAA;EACA,0BAAA;EACA,cAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,oCAAA;AAAJ;;AAGA;EACI,eAAA;EACA,iBAAA;AAAJ","sourcesContent":["@import url(../../../styles/variables.scss);\r\n\r\n.form-select:focus {\r\n    border: 1px solid #4f46e5;\r\n}\r\n\r\n.form-select {\r\n    border-radius: 6px;\r\n    padding: 12px 16px;\r\n    width: 250px;\r\n    height: 48px;\r\n}\r\n\r\n.form-control {\r\n    border-radius: 6px;\r\n    padding: 12px 16px 12px 40px;\r\n    height: 48px;\r\n}\r\n\r\n.form-control:focus {\r\n    border: 1px solid #4f46e5;\r\n}\r\n\r\n.form-control::placeholder {\r\n    font-size: 16px;\r\n}\r\n\r\n.form-control-wrap {\r\n    position: relative;\r\n}\r\n\r\n.control-img {\r\n    position: absolute;\r\n    top: 55%;\r\n    left: 15px;\r\n}\r\n\r\n.form-select {\r\n    background-image: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234f46e5' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m2 5 6 6 6-6'/%3e%3c/svg%3e\");\r\n    background-size: 16px 16px;\r\n    color: #adb5bd;\r\n}\r\n\r\n.form-select .select-option {\r\n    font-weight: 600;\r\n    color: #000;\r\n}\r\n\r\n.select-option:hover {\r\n    background-color: #4f46e5 !important;\r\n}\r\n\r\n.filter-btn {\r\n    cursor: pointer;\r\n    padding-top: 32px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
